<template>
  <div>
    <div class="input-group">
      <select class="col-10 form-select form-select-lg" :disabled="{disabled: data.running}">
        <option v-for="(v, i) in data.predefinedTimes" :value="i">{{ v }}</option>
      </select>
      <label class="col-2 input-group-text">LIVE</label>
    </div>
    <div class="row">
      <div @click="startOrPause" class="col-10 time-button">
        <div class="time">{{ time }}</div>
        <div v-if="data.running">
          <i class="fa-solid fa-pause"></i>
        </div>
        <div v-else>
        <i class="fa-solid fa-play"></i>
        </div>
      </div>
      <div class="col-2 d-grid">
          <button class="btn btn-secondary">
            <i class="fa-solid fa-plus"></i>
          </button>
        <button class="btn btn-secondary">
          <i class="fa-solid fa-minus"></i>
        </button>
        <button class="btn btn-primary btn-lg">
          <i class="fa-solid fa-refresh"></i>
        </button>
      </div>
    </div>
  </div>
</template>

<script setup>
import {computed, onMounted, reactive} from "vue";

const data = reactive({
  predefinedTimes: {
    1: "Uppräkning",
    30000: "00:30",
    45000: "00:45",
    60000: "01:00",
    90000: "01:30",
    120000: "02:00",
    180000: "03:00",
    300000: "05:00",
  },

  timeLeft: 60000,
  lastInterval: 0,

  countUp: false,
  running: false,
  interval: null // TODO: Is this used???
})

// TODO Audio que, on time end.
const time = computed(() => {
  var time = data.timeLeft
  if (data.timeLeft < 0 && !data.countUp) {
    time -= 1000
    // show negative
  } else {
    // dont show negative
  }

  // Blinking

  let m = pad(Math.abs(time / 60000));
  let s = pad(Math.abs((time / 1000) % 60))
  return m + ":" + s
})

function pad(num) {
  let n = Math.floor(num)
  return n > 9 ? n : '0' + n
}

function startOrPause() {
  data.running = !data.running
}

function plusOne() {
  data.timeLeft += 1000
}

function minusOne() {
  data.timeLeft -= 1000
}

function reset() {
  data.running = false
  data.timeLeft = data.time
}

onMounted(() => {
  setInterval(() => {
    let now = Date.now()
    let delta = now - data.lastInterval
    data.lastInterval = now
    if (data.running) {
      data.timeLeft -= delta
    }
  }, 100)
})

</script>

<style lang="scss" scoped>

.time-button {
  text-align: center;
  & i {
    font-size: 4vw;
  }
}
.time {
  font-size: 7vw;
}

</style>