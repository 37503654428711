<template>
  <div>
    <div class="row">
      <div class="col-3">

      </div>
      <div class="col-6">

        <div class="row">
          <div class="col-6">Name red</div>
          <div class="col-6"> Name blue</div>
        </div>

        <div class="row">
          <div class="col">warnings and fouls</div>
        </div>

        <div class="row">
          <div class="col">Corner Umpire points</div>
        </div>

        <div class="row">
          <div class="col">Total Points</div>
        </div>

        <div class="row">
          <div class="col">Manual result</div>
        </div>

      </div>
      <div class="col-3">

      </div>
    </div>



    <!-- Cation. Test stuff below. Not confirmed to work. Proceed Carefully -->


    <div class="row">

      <div class="col-3 vph-2">
        <Timer></Timer>
      </div>
      <div class="col-7 row-cols-2 vph-1">
        This is info
      </div>

      <div class="col-2 vph-3">
        Settings button
      </div>
    </div>

    <div class="row">
      <div class="col-3 vph-2">
        <Timer></Timer>
      </div>
    </div>

    <div>
      <hr>
      <router-link :to="{name: 'competition', params: {key: data.key}}" class="btn btn-secondary"><i
          class="fa-solid fa-chevron-left"></i> {{ t("back") }}
      </router-link>
    </div>

    <!-- Resultat Visa detta överst om det finns -->


    <!-- Ring name -->
    <!-- Timer Match-->
    <!-- Timer Other -->
    <!-- INFO Categoory name -->
    <!-- INFO Nubmer of competitors -->
    <!-- INFO Number of matches PROGRESS BAR HERE-->
    <!-- INFO Esimated Time -->
    <!-- INFO Elapsed time -->
    <!-- INFO List type -->

    <!-- INFO Match number -->
    <!-- INFO Number or rounds -->
    <!-- INFO Matchtime -->
    <!-- INFO Pause time -->


    <!-- Current match names -->
    <!-- Warnings and fouls -->
    <!-- Corner Umpire Points -->



    <!-- Buttons for warning and fouls -->
    <!-- Buttons for results -->

    <!-- Options button -->
      <!-- change match -->
      <!-- Change category -->
    <!-- SUGGESTED ACTION, one button, and move on. -->

    <!-- INFO This match -->
    <!-- INFO Previous match -->
    <!-- INFO Next match -->


    <!-- Settings and functions -->

    <!-- Finish match -->
    <!-- Finish Category -->
    <!-- Open Match -->
    <!-- Create extra -->
    <!-- Show points -->
    <!-- Link to list -->
    <!-- Choose match -->
    <!-- Choose category -->

    <!-- Rounds -->

    <!-- Pattern select and more -->
    <!-- SHowing selected patterns -->


  </div>
</template>

<script setup>
import {onMounted, reactive} from "vue"
import {useRoute} from "vue-router"
import {useI18n} from "vue-i18n"
import Timer from "@/Components/Timer.vue"

const {t} = useI18n()
const route = useRoute()
const data = reactive({
  key: route.params.key,

  // Category Channel
  pusher: null,
  ringChannel: null,
  matchChannel: null,
})

onMounted(() => {
  console.log(route.params)
  //axios.get(common.jirugiApi() + "/v1/pusher/ring/")

  // Do we have the ring id in url????
  //data.pusher = new Pusher(PUSHER_AUTH_KEY, JSON.parse(PUSHER_EXTRA_PARAMS_JSON))
  //data.ringCHannel = data.pusher.subscribe(route.params.occasion_id + "-ring-" + route.params.ring)


  // get some data here...
})
</script>
