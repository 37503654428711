<template>
  <div class="container">
    <div class="row">
      <div class="col-md-6 offset-md-3">

        <div class="text-center pt-4">
          <img :src="data.selfUrl + '/static/jirugi_logo_light.svg'" width="250" alt="JIRUGI">
          <h2>{{ t('settings') }}</h2>
        </div>

        <div>
          <h2 class="pt-5">{{ t("demo")}}</h2>
          <router-link class="btn btn-secondary" :to="{name: 'demo-sparring'}">{{ t('sparring') }}</router-link>
          <router-link class="btn btn-secondary" :to="{name: 'demo-pattern'}">Pattern</router-link>
        </div>

        <hr>


        <div>
          <h2 class="pt-5">{{ t("log")}}</h2>
          <router-link :to="{name: 'log'}" class="btn btn-primary px-5">View</router-link>
          <button @click="resetMessages" class="btn btn-danger">{{ t('reset_messages') }} ({{ data.resetMessagesCounter}})</button>
        </div>

        <hr>


        <h2 class="pt-5">{{ t("Server")}}</h2>
        <form @submit.prevent="save">
          <div>
            <div class="form-floating mb-3">
              <input type="text" class="form-control mb-1" id="endpoint" v-model="data.endpoint" :placeholder="t('endpoint')">
              <label for="endpoint">{{ t('endpoint') }}</label>
            </div>
            <div>
              jirugi (Legacy):
              <button @click.prevent="data.endpoint = ''" class="btn btn-sm btn-outline-primary">Default</button>
              <button @click.prevent="data.endpoint = 'https://jirugi.se/api'" class="btn btn-sm btn-outline-primary">Default, without proxy</button>
              <button @click.prevent="data.endpoint = 'http://localhost:8080/api'" class="btn btn-sm btn-outline-primary">Local
              </button>
            </div>
            <!--<div>
              Slab Platform:
              <button @click.prevent="data.endpoint = 'http://jirugi-umpire.local'" class="btn btn-sm btn-outline-secondary">Local</button>
              <button @click.prevent="data.endpoint = 'https://umpire.app.jirugi.se'" class="btn btn-sm btn-outline-secondary">Prod</button>
            </div>-->
          </div>
          <button type="submit" class="btn btn-success m-3">{{ t('submit') }}</button>
        </form>

        <hr>


        <div class="pt-2">
          <button class="btn btn-secondary pt-2" @click.prevent="router.back()"><i class="fa-solid fa-chevron-left"></i> {{ t("back") }}</button>
        </div>

      </div>
    </div>
  </div>
</template>

<script setup>

import {onMounted, reactive} from "vue";
import {useRouter} from "vue-router";
import {useI18n} from "vue-i18n";

const {t} = useI18n()
const router = useRouter()
const data = reactive({
  selfUrl: SELF_URL,
  endpoint: "",
  resetMessagesCounter: 3,
})

function resetMessages() {
  let cleared = "CLEARED!"
  if (data.resetMessagesCounter === 1 || data.resetMessagesCounter == cleared) {
    sessionStorage.removeItem("messages")
    data.resetMessagesCounter = cleared
  } else {
    data.resetMessagesCounter--
  }
}

function save() {
  if (data.endpoint === "") {
    sessionStorage.removeItem("jirugiApi")
  } else {
    sessionStorage.setItem("jirugiApi", data.endpoint)
  }
  router.back()
}

onMounted(() => {
  data.endpoint = JIRUGI_API_URL
  let su = sessionStorage.getItem("jirugiApi")
  if (su !== '') {
    data.endpoint = su
  }
})

</script>
