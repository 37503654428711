<template>
  <div class="container">
    <div class="row">
      <div class="col-md-6 offset-md-3">

        <div class="text-center pt-4">
          <img :src="data.selfUrl + '/static/jirugi_logo_light.svg'" width="250" alt="JIRUGI">
          <h2 class="pb-3">{{ t("umpire") }}</h2>
        </div>

        <div v-if="data.message !== ''" class="alert m-3" :class="'alert-' + data.messageColor">
          {{ data.message }}
        </div>

        <form @submit.prevent="send">
          <div class="form-floating mb-3">
            <input type="number" class="form-control mb-1" id="umpire-number" v-model="data.umpireNumber" :placeholder="t('number')">
            <label for="umpire-number">{{ t("number") }}</label>
          </div>

          <div class="d-grid gap-2">
            <button type="submit" class="btn btn-primary btn-lg">
              {{ t("login") }}
            </button>
          </div>
        </form>

        <div class="pt-2">
          <router-link :to="{name: 'login'}" class="btn btn-secondary pt-2"><i class="fa-solid fa-chevron-left"></i> {{ t("back") }}</router-link>
        </div>

      </div>
    </div>
  </div>

</template>

<script setup>

import {useI18n} from "vue-i18n";
import {onMounted, reactive} from "vue";
import common from "@/common";
import axios from "axios";
import {useRouter} from "vue-router";

const {t} = useI18n()
const router = useRouter()
const data = reactive({
  selfUrl: SELF_URL,
  umpireNumber: "",
  token: "",
  message: "",
  messageColor: "",
  interval: null,
});

function send() {
  if (data.umpireNumber === "" || isNaN(data.umpireNumber)) {
    return
  }
  if (data.token !== "") {
    return
  }
  data.token = (Math.random() + 1).toString(36).substring(2);
  axios.post(common.jirugiApi() + "/v1/quick_login", {token: data.token, umpire_number: data.umpireNumber}).then(resp => {
    if (resp.data.success) {
      data.message = data.token
      data.messageColor = "success"
      data.interval = setInterval(loginPoll, 1000)
      setTimeout(function () {
        clearInterval(data.interval)
        data.message = "Timeout"
        data.messageColor = "danger"
      }, 60 * 1000)
    }
  }).catch(err => {
    data.message = err.data.message
    data.messageColor = "danger"
  })
}

function loginPoll() {
  axios.get(common.jirugiApi() + "/v1/quick_login_get?token=" + data.token).then(resp => {
    if (resp.status === 202) {
      return
    }
    if (resp.status === 200) {
      clearInterval(data.interval)
      data.message = t("ok_redirect")
      data.messageColor = "success"
      sessionStorage.setItem("accessToken", resp.data.token)
      sessionStorage.setItem("email", resp.data.email)
      sessionStorage.setItem('latestUmpireNumber', data.umpireNumber)
      axios.defaults.headers.common['Authorization'] = `Bearer ${sessionStorage.getItem('accessToken')}`;
      if (typeof js_bridge !== 'undefined') {
        js_bridge.storageSet("accessToken", resp.data.token)
        js_bridge.storageSet("latestUmpireNumber", data.umpireNumber)
      }
      router.push({name: "home"})
    }
  }).catch(err => {
    clearInterval(data.interval)
    if (err.status === 401) {
      data.message = t("no_unauthorized")
      data.messageColor = "danger"
    }
    data.message = err.data.message
    data.messageColor = "danger"
  })
}

onMounted(() => {
  if (typeof js_bridge !== 'undefined') {
    data.umpireNumber = js_bridge.storageGet("latestUmpireNumber")
  } else {
    data.umpireNumber = sessionStorage.getItem('latestUmpireNumber')
  }
});

</script>
